import { useCallback, useRef } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { BodySmall } from 'components/Text/BodySmall';
import { H2 } from 'components/Text/H2';
import { SmallTile } from 'components/Tile/SmallTile';
import { VersionedRouterLink } from 'components/VersionedLink/VersionedRouterLink';
import { useElementReachedEffect } from 'hooks/viewport/useElementReachedEffect';
import { salariesRouteGroup } from 'modules/salaries/routing/salaries.routes';
import { colors, cssBreakpoints, fontWeights } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEventOnce } from 'utils/analytics/track/trackEventOnce';
import { getStaticImageSrc } from 'utils/getImageSrc';
import { scrollToTop } from 'utils/scroll';

const TileLink = styled(VersionedRouterLink)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  color: ${colors.mediumContentGrey} !important;
  text-decoration: none;
`;

const StyledSearchTile = styled(SmallTile)`
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 4px solid ${colors.brandYellow};

  &:hover a {
    text-decoration-line: underline;
    text-decoration-color: ${colors.hoverBlue};
  }

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

const ChartImage = styled(Box)`
  background-image: url('${() => getStaticImageSrc('yellow-chart.svg')}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  margin-right: 24px;
  width: 100%;
  height: 90px;
  position: absolute;
  bottom: -3px;
`;

export function CompensationSurveyAd() {
  const elementRef = useRef<HTMLDivElement>(null);

  useElementReachedEffect({
    ref: elementRef,
    threshold: 0.5,
    onReach: useCallback(() => {
      trackEventOnce('Displayed: Salary Survey Ad', {
        context: 'Interstitial',
      });
    }, []),
  });

  return (
    <StyledSearchTile
      type="button"
      withHoverEffect
      data-qa-id="compensation-survey-ad"
    >
      <TileLink
        title="Take Salary Survey"
        to={salariesRouteGroup.fullPath}
        onClick={() => {
          scrollToTop();
          trackClicked('Salary Survey Ad', { context: 'Interstitial' });
        }}
      />
      <Box ref={elementRef} pl={[0, 15]} pb={[32, 10]} mt={[14, 0]}>
        <BodySmall color={colors.elementGrey}>
          {getText('Nonprofit Salary Explorer')}
        </BodySmall>

        <H2
          mb="4px"
          mt="9px"
          lineHeight="1.375"
          fontWeight={fontWeights.semiBold}
        >
          {getText('Try out our salary tool, built with you in mind 💰')}
        </H2>

        <BodySmall>
          {getText(
            'Calculate your Nonprofit Salary Explorer estimate in minutes',
          )}
        </BodySmall>

        <Box mt={[25, 25]}>
          <Button
            type="link"
            variant="tertiary"
            iconPosition="right"
            icon={{ type: 'normalized', name: 'arrow-right' }}
            noPadding
            data-qa-id="take-survey-button"
            href={salariesRouteGroup.fullPath}
            onClick={() => {
              scrollToTop();
              trackClicked('Salary Survey Ad CTA Button', {
                context: 'Interstitial',
              });
            }}
          >
            {getText('Take Our Survey')}
          </Button>
        </Box>
      </Box>

      <ChartImage
        position="absolute"
        pointerEvents="none"
        right={0}
        width={1}
        height="100%"
      />
    </StyledSearchTile>
  );
}
