import { useEffect } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { BodyMicro } from 'components/Text/BodyMicro';
import { H5 } from 'components/Text/H5';
import { Text } from 'components/Text/Text';
import { ResumeDatabaseResponseContainer } from 'containers/ResumeDatabaseResponseContainer';
import { colors, gutterWidth, transition } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEventOnce } from 'utils/analytics/track/trackEventOnce';
import { getStaticImageSrc } from 'utils/getImageSrc';
import { showModal } from 'zustand-stores/modalStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

const CloseButton = styled(Button)`
  display: block;
  opacity: 0.7;
  transition: all ${transition};

  &:hover {
    opacity: 1;
  }
`;

export function ResumeDatabaseSidebarAd() {
  const { user } = useUserEnvironment();
  useEffect(() => {
    trackEventOnce('Displayed: Resume Database Ad', {
      context: 'Search Promo',
    });
  }, []);
  const redirectTo = '/dashboard/employment-profile/get-discovered';

  const showSignUpModal = () => {
    showModal('SIGNUP', { redirectTo });
  };

  return (
    <Box
      data-qa-id="sidebar-ad"
      data-ad-type="resume-database"
      px={[gutterWidth / 2, null, null, 0]}
    >
      <Box
        position="relative"
        overflow="hidden"
        bg={colors.brandBlue}
        borderRadius="8px"
        color="white"
        mb={32}
      >
        <Box
          position="absolute"
          left={0}
          top={0}
          width={1}
          height="100%"
          backgroundImage={`url('${getStaticImageSrc(
            'resume-database-ad.svg',
          )}')`}
          backgroundSize="cover"
          backgroundPosition="center"
        />
        <Box position="relative" width={1} p={32}>
          <H5 mb={10} lineHeight={1.375}>
            {
              /* TRANSLATORS: Resume database promotional banner */
              getText('Organizations are looking for someone just like you!')
            }
          </H5>
          <BodyMicro lineHeight={1.375}>
            {
              /* TRANSLATORS: Resume database promotional banner */
              getText(`Get discovered today.`)
            }
          </BodyMicro>

          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            mx="-6px"
            mt={24}
          >
            <Box p="6px" flex="1 0 auto">
              <Button
                type="link"
                fullWidth
                href={redirectTo}
                variant="secondary-white"
                onClick={(e) => {
                  trackClicked('Add Resume', {
                    context: 'Search Sidebar',
                  });

                  if (!user) {
                    e.preventDefault();
                    showSignUpModal();
                  }
                }}
              >
                <Box
                  height={24}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text styleAs="h5" my={0}>
                    {user?.resumeId
                      ? getText('Share Your Resume')
                      : getText('Add Your Resume')}
                  </Text>
                </Box>
              </Button>
            </Box>

            <Box p="6px" flex="1 0 auto" display="flex" justifyContent="center">
              <ResumeDatabaseResponseContainer>
                {({ dismiss }) => (
                  <CloseButton
                    type="button"
                    data-qa-id="close-sidebar-ad-button"
                    onClick={() => {
                      dismiss();
                      trackClicked('Dismiss Resume Database CTA', {
                        context: 'Search Sidebar',
                      });
                    }}
                  >
                    <BodyMicro $textDecoration="underline">
                      {getText('No, thanks')}
                    </BodyMicro>
                  </CloseButton>
                )}
              </ResumeDatabaseResponseContainer>
            </Box>
          </Box>
        </Box>

        <Box position="absolute" top={12} right={12}>
          <ResumeDatabaseResponseContainer>
            {({ dismiss }) => (
              <CloseButton
                type="button"
                data-qa-id="close-sidebar-ad-button"
                onClick={() => {
                  dismiss();
                  trackClicked('Dismiss Resume Database CTA', {
                    context: 'Search Sidebar',
                  });
                }}
                tabIndex={-1}
              >
                <Icon name="close" size={12} />
              </CloseButton>
            )}
          </ResumeDatabaseResponseContainer>
        </Box>
      </Box>
    </Box>
  );
}
