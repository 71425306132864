import { ActionDateFacetValue } from 'modules/search/constants/searchFacets/actionDateFacet';
import { SearchFacet, SearchFacetName } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

function getValueForFacet(
  facet: SearchFacet,
  queryParams: Record<SearchFacetName, string | string[]>,
): string | Array<string> | ActionDateFacetValue | number | undefined {
  if (facet.type === 'array') {
    const paramValue = queryParams[facet.name];
    if (!paramValue) return undefined;

    return Array.isArray(paramValue) ? paramValue : [paramValue as string];
  }

  if (facet.type === 'date') {
    const endsGT =
      'endsGT' in queryParams && typeof queryParams.endsGT === 'string'
        ? queryParams.endsGT
        : undefined;

    const startsLT =
      'startsLT' in queryParams && typeof queryParams.startsLT === 'string'
        ? queryParams.startsLT
        : undefined;

    if (!endsGT && !startsLT) return;
    return { endsGT, startsLT };
  }

  if (!queryParams[facet.name]) return;
  return queryParams[facet.name] as string;
}

export function searchConvertFiltersByNameToFiltersById(
  facets: SearchFacet[],
  queryParams: Record<SearchFacetName, string | string[]>,
): Omit<SearchFiltersById, 'type'> {
  const filters: Omit<SearchFiltersById, 'type'> = {};

  facets.forEach((facet) => {
    const value = getValueForFacet(facet, queryParams);
    if (value) filters[facet.id] = value;
  });

  return filters;
}
