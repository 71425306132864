import { SelectInput } from 'components/SelectInput/SelectInput';
import { SearchSortType } from 'modules/search/types/SearchSortType';

type Props = {
  value: SearchSortType;
  options: Array<{
    name: string;
    param: 'relevance' | 'newest';
  }>;
  onChange: (sort: SearchSortType) => void;
};

export function SortMenu({ value, options, onChange }: Props) {
  return (
    <SelectInput
      data-qa-id="sort-menu-toggle"
      value={value}
      options={options.map((option) => ({
        label: option.name,
        value: option.param,
      }))}
      onChange={(option) => {
        onChange(option as SearchSortType);
      }}
      small
    />
  );
}
