import { useMemo } from 'react';
import { useStickyBox } from 'react-sticky-box';

import { ResumeDatabaseSidebarAd } from 'components/Ads/ResumeDatabaseSidebarAd';
import { SalarySurveySidebarAd } from 'components/Ads/SalarySurveySidebarAd';
import { Hide } from 'components/Hide/Hide';
import { Icon } from 'components/Icon/Icon';
import { SearchFiltersSidebarContainer } from 'modules/search/containers/SearchFiltersSidebar/SearchFiltersSidebarContainer';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { AdSection } from 'types/Ads/AdSection';
import { AdType } from 'types/Ads/AdType';
import { toggleDocumentScroll } from 'utils/ui/toggleDocumentScroll';
import { useAdType } from 'zustand-stores/adStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import {
  SearchSidebarMobileToggleLabel,
  SearchSidebarMobileToggleLabelHideText,
  SearchSidebarMobileToggleLabelShowText,
  SearchSidebarStickyBox,
  SearchSidebarToggleHolder,
  searchSidebarStickyBoxClassName,
} from './SearchSidebar.styled';

type Props = {
  initialized: boolean;
  isInSearchLandingPage: boolean;
  allowAds: boolean;
  searchFacets: SearchFacet[];
};

export function SearchSidebar({
  initialized,
  isInSearchLandingPage,
  allowAds,
  searchFacets,
}: Props) {
  const { user } = useUserEnvironment();
  const adType = useAdType(AdSection.SIDEBAR);

  const showAd =
    allowAds &&
    (adType === AdType.RESUME_DB || adType === AdType.SALARY_SURVEY);

  /**
   * If we don't include the ad as part of the sticky box, scrolling up causes
   * the content to jump while JS is running and calculating the new positions
   */
  const offsetTop = useMemo(() => {
    if (!showAd) return 100;

    if (adType === AdType.SALARY_SURVEY) return -100;

    // ResumeDatabaseAd
    return -200;
  }, [adType, showAd]);

  const stickyRef = useStickyBox({
    offsetTop,
    offsetBottom: 10,
  });

  return (
    <>
      <SearchSidebarToggleHolder>
        <SearchSidebarMobileToggleLabel data-qa-id="toggle-search-filters">
          <input
            type="checkbox"
            onChange={(event) =>
              toggleDocumentScroll({ show: !event.target.checked })
            }
          />

          <SearchSidebarMobileToggleLabelHideText>
            <span>{getText('Hide Filters')}</span>
            <Icon name="close" size={20} colorName="brandBlue" />
          </SearchSidebarMobileToggleLabelHideText>

          <SearchSidebarMobileToggleLabelShowText>
            <span>{getText('Filters')}</span>
            <Icon name="filters" size={20} colorName="brandBlue" />
          </SearchSidebarMobileToggleLabelShowText>
        </SearchSidebarMobileToggleLabel>
      </SearchSidebarToggleHolder>

      <SearchSidebarStickyBox
        ref={stickyRef}
        className={searchSidebarStickyBoxClassName}
      >
        {showAd && (
          <Hide xs sm md={Boolean(user)}>
            {adType === AdType.RESUME_DB && <ResumeDatabaseSidebarAd />}
            {adType === AdType.SALARY_SURVEY && <SalarySurveySidebarAd />}
          </Hide>
        )}

        <SearchFiltersSidebarContainer
          initialized={initialized}
          isInSearchLandingPage={isInSearchLandingPage}
          searchFacets={searchFacets}
        />
      </SearchSidebarStickyBox>
    </>
  );
}
